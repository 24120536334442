<div dougsModalTitle>
  <h6>Sélectionnez une raison de complétion</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <ul class="select-candidate">
    <li *ngFor="let item of data" class="p-8" (click)="selectItem(item)">
      {{ item.label }}
    </li>
  </ul>
</div>
