import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MODAL_DATA, ModalCloseDirective, ModalContentDirective, ModalRef, ModalTitleDirective } from '@dougs/ds';
import { CompletionReason } from '@dougs/task/dto';

@Component({
  selector: 'dougs-complete-task-modal',
  templateUrl: './complete-task-modal.component.html',
  styleUrls: ['./complete-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, NgFor],
})
export class CompleteTaskModalComponent {
  constructor(
    @Inject(MODAL_DATA) public data: CompletionReason[],
    private readonly modalRef: ModalRef<CompletionReason, CompletionReason[]>,
  ) {}

  selectItem(completionReason: CompletionReason): void {
    this.modalRef.close(completionReason);
  }
}
